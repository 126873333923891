const password = {
    lowerCaseLetters: /[a-z]/,
    upperCaseLetters: /[A-Z]/,
    numbers: /[0-9]/,
    length: /^.{8,100}$/,
};

const passwordStrength = {
    lowerCaseLetters: /[a-z]/,
    upperCaseLetters: /[A-Z]/,
    numbers: /[0-9]/,
    length: /^.{8,100}$/,
    // Commented in task 1679 (maybe uncommented in future)
    //lowerCaseLettersOrder: /[a-z]+[^a-z]+[a-z]/,
    //upperCaseLettersOrder: /[A-Z]+[^A-Z]+[A-Z]/,
    //numbersOrder: /[0-9]+[^0-9]+[0-9]/,
};

export const criteries = ["lowerCaseLetters", "upperCaseLetters", "numbers", "length"];

/**
 * Calculates the strength of a password based on a set of predefined patterns.
 *
 * @param {string} data - The password to be checked.
 * @return {number} The strength of the password as a percentage.
 */
export const checkStrength = (data) => {
    const totalStrength = Object.keys(passwordStrength).length;
    let strength = 0;

    Object.values(passwordStrength).forEach((pattern) => {
        if (pattern.test(data)) {
            strength++;
        }
    });

    strength = Math.round((strength * 100) / totalStrength);

    return strength;
};

/**
 * Calculates the standard strength of a password based on a set of predefined patterns.
 *
 * @param {string} data - The password to be checked.
 * @return {number} The standard strength of the password.
 */
export const checkStandard = (data) => {
    let strength = 0;

    Object.values(password).forEach((pattern) => {
        if (pattern.test(data)) {
            strength++;
        }
    });

    return strength;
};

/**
 * Checks if the given value matches the specified criteria using a predefined pattern.
 *
 * @param {string} criteria - The criteria to be tested against the value.
 * @param {string} value - The value to be tested against the criteria.
 * @return {boolean} True if the value matches the criteria, false otherwise.
 */
export const test = (criteria, value) => {
    return password[criteria].test(value);
};

/**
 * Checks if the given values are identical and returns an empty string if they are,
 * otherwise returns the string "error".
 *
 * @param {any} first - The first value to compare.
 * @param {any} second - The second value to compare.
 * @return {string} An empty string if the values are identical, otherwise the string "error".
 */
export const checkIdentity = (first, second) => {
    return first === second ? "" : "error";
};
