<template>
    <div class="notification" v-if="!disabled">
        <Popover clickable position="bottom">
            <slot :unseenCount="data.unseen" />
            <template #body>
                <div class="notification__wrapper">
                    <NotificationHeader
                        v-model:showSettings="data.showSettings"
                        :unseen="data.unseen"
                        @readAll="readAll"
                    />
                    <transition name="appearance" mode="out-in" :duration="250">
                        <NotificationItems
                            v-if="!data.showSettings"
                            v-model:data="data.items"
                            v-model:loading="data.isLoading"
                            v-model:meta="data.meta"
                            @markAs="markAs"
                            @deleteItem="deleteItem"
                        />
                        <NotificationSettings
                            v-else
                            v-model:loading="data.isLoading"
                            v-model:data="data.preferences"
                            @settingsUpdate="settingsUpdate"
                        />
                    </transition>
                    <NotificationLoader v-if="data.isLoading" />
                </div>
            </template>
        </Popover>
    </div>
</template>

<script>
import { defineComponent, onBeforeUnmount, onMounted, provide, reactive, ref } from "vue";

import { Popover } from "@/shared/ui";
import { debounce, deepCopy } from "@/shared/lib";
import { api_initialize, api_mark_as, api_unseen, api_messages, api_read } from "../../api";
import { useApi } from "@/shared/api";
import { setToken } from "../../lib/methods";
import { useInitializeSocket } from "../../lib/socket";

import NotificationHeader from "../NotificationHeader";
import NotificationItems from "../NotificationItems";
import NotificationSettings from "../NotificationSettings";
import NotificationLoader from "../NotificationLoader";

const NotificationWrapper = defineComponent({
    name: "NotificationWrapper",
    props: {
        links: {
            type: Object,
        },
        info: {
            type: Object,
        },
    },
    components: { Popover, NotificationHeader, NotificationItems, NotificationSettings, NotificationLoader },
    setup(props) {
        const data = reactive({
            showSettings: false,
            isLoading: false,
            preferences: [],
            items: [],
            unseen: 0,
            meta: { page: 0, pageSize: 0, totalCount: 0 },
        });
        const headers = ref({});

        const disabled = ref(false);

        provide("request_info", { links: props.links, headers: headers });

        const { socket, initializeSocket, disconnectSocket } = useInitializeSocket({
            socketUrl: props.links.websocket,
        });

        const settingsUpdate = (item) => {
            const key = item.template?.id ? "id" : "_id";
            data.preferences = deepCopy(data.preferences).map((el) => {
                if (item?.template?.[key] && el?.template?.[key] && el.template[key] === item.template[key]) {
                    return item;
                }
                return el;
            });
        };

        const markAs = async ({ item, markAll, mark = { read: true, seen: true } }) => {
            let items = [];

            if (markAll) {
                items = data.items.filter((el) => !el.read || !el.seen);
                items = items.map((el) => el.id);
            } else {
                items = [item.id];
            }

            if (items.length) {
                const sendData = { messageId: items, mark };
                const res = await useApi.post({
                    link: api_mark_as(props.links.backend),
                    data: sendData,
                    headers: headers.value,
                    headless: true,
                });
                if (res?.data) {
                    itemsUpdate(res.data);
                }
            }
        };

        const deleteItem = async (item) => {
            const res = await useApi.delete({
                link: api_messages(props.links.backend, item.id),
                headers: headers.value,
                headless: true,
            });
            if (res) {
                data.meta.pageSize--;
                data.meta.totalCount--;

                data.items = data.items.filter((el) => el._id !== res.data._id);
            }
        };

        const deleteAll = async () => {
            for (let el of data.items) {
                await deleteItem(el);
            }
        };

        const itemsUpdate = (item) => {
            if (!Array.isArray(item)) {
                const index = deepCopy(data.items).findIndex((el) => el.id === item.id || el._id === item._id);

                data.items[index] = deepCopy({ ...data.items[index], ...item });
            } else {
                item.forEach((el) => {
                    const index = data.items.findIndex((item_el) => item_el.id === el.id || item_el._id === el._id);
                    data.items[index] = deepCopy({ ...data.items[index], ...el });
                });
            }
        };

        const readAll = async () => {
            if (data.unseen) {
                const res = await useApi.post({
                    link: api_read(props.links.backend),
                    data: {},
                    headers: headers.value,
                    headless: true,
                });

                if (res?.data) {
                    data.unseen = 0;

                    let items = data.items.filter((el) => {
                        if (!el.read) {
                            el.read = true;
                            el.seen = true;
                            return true;
                        }
                        return false;
                    });
                    itemsUpdate(items);
                }
            }
        };
        const init = async () => {
            // hide notification widget, when api url is not set
            if (!props.links.backend) {
                disabled.value = true;
                return;
            }

            let res = await useApi.post({
                link: api_initialize(props.links.backend),
                data: props.info,
                headers: headers.value,
                ignoreErrors: true,
                headless: true,
            });

            if (!res) {
                disabled.value = true;
                return;
            }

            initializeSocket(res.data?.token);

            if (!socket.value) {
                disabled.value = true;
                return;
            }

            socket.value.on("unseen_count_changed", (res) => {
                if (Number.isInteger(res?.unseenCount)) {
                    data.unseen = res.unseenCount;
                }
            });

            let messages = [];

            const addMessagesToItems = debounce(() => {
                data.items = [...messages, ...data.items];
                data.meta.totalCount += messages.length;
                data.meta.page = Math.floor(data.items.length / 10) - 1;
                messages = [];
            }, 1500);

            socket.value.on("notification_received", (res) => {
                if (res?.message) {
                    messages.unshift(res.message);
                    addMessagesToItems();
                }
            });

            headers.value = setToken(res?.data?.token);

            res = await useApi.get({
                link: api_unseen(props.links.backend),
                headers: headers.value,
                ignoreErrors: true,
                headless: true,
            });

            data.unseen = res?.data?.count;
        };
        onMounted(async () => {
            init();
        });

        onBeforeUnmount(() => {
            disconnectSocket(); // Clean up socket connection
        });

        return { data, disabled, deleteAll, settingsUpdate, itemsUpdate, markAs, deleteItem, readAll };
    },
});
export default NotificationWrapper;
</script>

<style lang="scss">
@import "./assets/notification-wrapper";
</style>
