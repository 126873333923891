import { localization } from "@/shared/lib";
import { jobs, gas_appliances, oil_appliances, invoices, history, archived } from "./tables";
import { computed } from "vue";
import {
    addressLookup,
    fullWidthCol,
    inputDate,
    inputStandard,
    searchRecord,
    standardCustomButton,
    textArea,
    textHead,
    translucentCustomButton,
    transparentCustomButton,
    whiteCustomButton,
} from "./defaultInputProps";

const standard_form_template = [
    {
        component: {
            ...textHead,
            title: localization("records.property.occupants_details"),
            lg: 6,
        },

        child: [
            {
                component: {
                    ...inputStandard,
                    ...fullWidthCol,

                    title: localization("records.fields.name"),
                    name: "installname",
                },
            },
            {
                component: {
                    ...inputStandard,
                    ...fullWidthCol,

                    title: localization("records.fields.phone"),
                    validationType: "phone",
                    name: "installphone",
                },
            },
            {
                component: {
                    ...inputStandard,
                    ...fullWidthCol,

                    title: localization("records.fields.email"),
                    type: "email",
                    name: "email",
                    validationType: "email",
                    validation: true,
                },
            },
            {
                component: {
                    ...textHead,
                    title: localization("records.property.gas_service_date"),
                    class: "mt-16 mb-8",
                },
                child: [
                    {
                        component: {
                            ...inputDate,
                            ...fullWidthCol,
                            name: "gsexpiry",
                        },
                    },
                ],
            },
        ],
    },

    {
        component: {
            ...textHead,
            title: localization("records.fields.notes"),
        },
        child: [
            {
                component: {
                    ...textArea,
                    name: "access_notes",
                },
            },
        ],
    },
];

const edit_form_template = [
    {
        component: {
            ...textHead,
            title: localization("records.property.details"),
            lg: 6,
        },
        child: [
            {
                component: {
                    ...inputStandard,
                    ...fullWidthCol,
                    title: localization("records.fields.building_or_house"),
                    name: "building",
                },
            },
            {
                component: {
                    ...inputStandard,
                    ...fullWidthCol,
                    title: localization("records.fields.street_address"),
                    required: true,
                    name: "street",
                },
            },
            {
                component: {
                    ...inputStandard,
                    ...fullWidthCol,
                    title: localization("records.fields.town"),
                    name: "town",
                },
            },
            {
                component: {
                    ...inputStandard,
                    ...fullWidthCol,
                    title: localization("records.fields.region"),
                    name: "region",
                },
            },
            {
                component: {
                    ...inputStandard,
                    ...fullWidthCol,
                    title: localization("records.fields.post_code"),
                    name: "postcode",
                },
            },
        ],
    },
    ...standard_form_template,
];

const create_form_template = [
    {
        component: {
            ...textHead,
            title: localization("records.customer.details"),
        },
        child: [
            {
                component: {
                    ...searchRecord,
                    title: localization("records.fields.find_or_create_customer"),
                    name: "customer",
                    type: "customer",
                },
            },
        ],
    },
    {
        component: {
            ...textHead,
            title: localization("records.property.details"),
            lg: 6,
        },
        child: [
            {
                component: {
                    ...transparentCustomButton,
                    ...fullWidthCol,
                    text: localization("records.fields.copy_customer"),
                    icon: "copy_boxes",
                    type: "secondary",
                    action: { key: "copy", value: "customer" },
                },
            },
            {
                component: {
                    ...addressLookup,
                    ...fullWidthCol,
                    title: localization("records.fields.address_lookup"),
                    name: "address_lookup",
                },
            },
            {
                component: {
                    ...inputStandard,
                    ...fullWidthCol,
                    title: localization("records.fields.building_or_house"),
                    name: "building",
                },
            },
            {
                component: {
                    ...inputStandard,
                    ...fullWidthCol,
                    title: localization("records.fields.street_address"),
                    name: "street",
                    required: true,
                },
            },
            {
                component: {
                    ...inputStandard,
                    ...fullWidthCol,
                    title: localization("records.fields.town"),
                    name: "town",
                },
            },
            {
                component: {
                    ...inputStandard,
                    ...fullWidthCol,
                    title: localization("records.fields.region"),
                    name: "region",
                },
            },
            {
                component: {
                    ...inputStandard,
                    ...fullWidthCol,
                    title: localization("records.fields.post_code"),
                    name: "postcode",
                },
            },
        ],
    },
    ...standard_form_template,
];

const create_aside_template = [
    {
        component: {
            componentName: "Card",
            ultrasmall: true,
        },
        child: [
            {
                component: {
                    ...standardCustomButton,
                    text: localization("buttons.save"),
                    action: "save",
                    required: true,
                },
            },
            {
                component: {
                    ...transparentCustomButton,
                    text: localization("buttons.cancel"),
                    buttonComponent: "a",
                    href: "/property/index",
                },
            },
        ],
    },
];

const edit_aside_template = computed({
    get() {
        return (data) => [
            {
                component: {
                    componentName: "Card",
                    ultrasmall: true,
                    gap: 8,
                },
                child: [
                    {
                        component: {
                            ...standardCustomButton,
                            text: localization("records.button.add_job"),
                            buttonComponent: "a",
                            href: `/job/edit/?prop_id=${data.id}`,
                            icon: "add_page",
                            position: "right",
                            disabled: +data.archive,
                        },
                    },
                    {
                        component: {
                            ...standardCustomButton,
                            text: localization("records.button.add_gas_appliance"),
                            action: { key: "add", value: "gas_appliance" },
                            type: "white",
                            icon: "plus",
                            position: "right",
                            disabled: +data.archive,
                        },
                    },
                    {
                        component: {
                            ...whiteCustomButton,
                            text: localization("records.button.add_oil_appliance"),
                            action: { key: "add", value: "oil_appliance" },
                            icon: "plus",
                            position: "right",
                            disabled: +data.archive,
                        },
                    },
                    {
                        component: {
                            ...whiteCustomButton,
                            href: `/property/service/${data.id}?who=0`,
                            buttonComponent: "a",
                            target: "_blank",
                            name: "customer_letter",
                            text: localization("records.fields.customer_reminder_letter"),
                        },
                    },
                    {
                        component: {
                            ...whiteCustomButton,
                            buttonComponent: "a",
                            href: `/property/service/${data.id}?who=1`,
                            target: "_blank",
                            name: "tentant_letter",
                            text: localization("records.fields.tenant_reminder_letter"),
                        },
                    },
                    {
                        component: {
                            ...whiteCustomButton,
                            text: localization("records.fields.change_customer"),
                            action: { key: "changeParent", value: "customer" },
                            disabled: +data.archive,
                        },
                    },
                ],
            },

            {
                component: {
                    componentName: "div",
                    gap: 8,
                },
                child: [
                    {
                        component: {
                            ...transparentCustomButton,
                            text: localization("buttons.save"),
                            action: "save",
                            disabled: +data.archive,
                            required: true,
                        },
                    },
                    {
                        component: {
                            ...translucentCustomButton,
                            text: +data.archive ? localization("buttons.unarchive") : localization("buttons.delete"),
                            action: { key: "delete", archive: data.archive },
                        },
                    },
                ],
            },
        ];
    },
});

const custom_aside_template = [
    {
        component: {
            componentName: "div",
            content: "flex-end",
        },
        child: [
            {
                component: {
                    ...standardCustomButton,
                    text: localization("buttons.save"),
                    action: "save",
                },
            },
            {
                component: {
                    ...transparentCustomButton,
                    text: localization("buttons.cancel"),
                    action: "cancel",
                },
            },
        ],
    },
];

export const tables = [jobs, gas_appliances, oil_appliances, invoices, history, archived];

export const aside_template = {
    create: create_aside_template,
    edit: edit_aside_template.value,
    custom: custom_aside_template,
};

export const form_template = {
    create: [
        {
            component: {},
            child: create_form_template,
        },
    ],
    edit: [
        {
            component: {},
            child: edit_form_template,
        },
    ],
};

export const copy_templates = {
    building: "building",
    email: "email",
    name: "installname",
    phone: "installphone",
    postcode: "postcode",
    region: "region",
    street: "street",
    success: "success",
    town: "town",
};

export const archive_checkboxes = ["cascade_job"];

export const type = "property";

export const ignored_columns = ["customer", "property"];

export const start_data = {
    customer: { title: "", value: "" },
    address_lookup: { title: "", value: "" },
};
