import { ref } from "vue";
import { io } from "socket.io-client";

export const useInitializeSocket = ({ socketUrl }) => {
    const socketRef = ref();

    const disconnectSocket = () => {
        socketRef.value && socketRef.value.disconnect();
    };

    const initializeSocket = (token) => {
        if (!token) {
            console.warn("Socket initialization requires a token.");
            return;
        }

        disconnectSocket();

        socketRef.value = io(socketUrl, {
            reconnectionDelayMax: 10000,
            transports: ["websocket"],
            auth: { token },
        });

        socketRef.value.on("connect_error", (error) => {
            console.info("Socket connection error:", error.message);
        });

        socketRef.value.on("connect", () => {
            console.info("Socket Connected");
        });
    };

    return {
        socket: socketRef,
        initializeSocket,
        disconnectSocket,
    };
};
